import { useState } from "react";
import styles from "./account-settings-download-files.module.css";

export const AccountSettingsDownloadFiles = () => {
  //USESTATE VARIABLES
  const [platformSelected, setPlatformSelected] = useState("ALL");
  const [showPlatforms, setShowPlatforms] = useState(false);

  return (
    <div className={styles["download-files"]}>
      <div className={styles.header}>
        <h1>Download files</h1>
        <div
          className={styles["all-platforms"]}
          onClick={() => setShowPlatforms(!showPlatforms)}
        >
          {" "}
          <img
            className={styles["platforms-icon"]}
            src="/assets/images/marketplace/icons/all-items-navbar.svg"
            alt=""
          />{" "}
          <span className={styles["all-platforms-text"]}>
            {platformSelected === "ALL" && "All platforms"}
            {platformSelected === "PC" && "Desktop"}
            {platformSelected === "MOBILE" && "Mobile"}
          </span>
          <img
            className={`${styles.arrow} ${
              showPlatforms && styles["arrow-open"]
            }`}
            src="/assets/images/icons/bottom-arrow-icon.svg"
            alt=""
          />
          {showPlatforms && (
            <div className={styles["all-platforms-dropdown"]}>
              <ul>
                <li onClick={() => setPlatformSelected("ALL")}>
                  All Platforms
                </li>
                <li onClick={() => setPlatformSelected("PC")}>Desktop</li>
                <li onClick={() => setPlatformSelected("MOBILE")}>Mobile</li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {(platformSelected === "PC" || platformSelected === "ALL") && (
        <div className={styles.pc}>
          <div className={styles["arker-2"]}>
            <span>Arker 2.0 (beta version)</span>
            <a
              href="https://arker-game.s3.eu-west-1.amazonaws.com/Arker3D_0.1.zip"
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles["download-button"]}>
                <img src="/assets/images/icons/download-icon.svg" alt="" />{" "}
                Download for windows
              </button>
            </a>

            {/* <button className={styles["download-button"]}>
            <img src="/assets/images/icons/download-icon.svg" alt="" /> Download
            for mac
          </button> */}
          </div>
          <div className={styles["arker-1"]}>
            <span>Arker 1.0</span>
            <a
              href="https://downloads.playarker.com/Arker%20Launcher.exe"
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles["download-button"]}>
                <img src="/assets/images/icons/download-icon.svg" alt="" />{" "}
                Download for windows
              </button>
            </a>

            {/* <button className={styles["download-button"]}>
            <img src="/assets/images/icons/download-icon.svg" alt="" /> Download
            for mac
          </button> */}
          </div>
        </div>
      )}

      {(platformSelected === "ALL" || platformSelected === "MOBILE") && (
        <div className={styles.mobile}>
          <span>Arker mobile</span>
          <div className={styles["mobile-buttons"]}>
            <a
              href="https://play.google.com/store/apps/details?id=com.arkerlabs.arker"
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles["download-button"]}>
                <img src="/assets/images/icons/download-icon.svg" alt="" />
                Play store (Android)
              </button>
            </a>

            <a
              href="https://apps.apple.com/us/app/arker-the-legend-of-ohm/id1369545258"
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles["download-button"]}>
                <img src="/assets/images/icons/download-icon.svg" alt="" />
                App store (IOS)
              </button>
            </a>
          </div>
        </div>
      )}

      <div className={styles.others}>
        <span>Others</span>
        <div className={styles["others-buttons"]}>
          <a
            href="https://playarker.com/assets/docs/whitepaper.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={`${styles["fit-button"]} ${styles["download-button"]}`}
            >
              <img src="/assets/images/icons/download-icon.svg" alt="" />
              whitepaper
            </button>
          </a>

          <a
            href="https://playarker.com/assets/docs/deck.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={`${styles["fit-button"]} ${styles["download-button"]}`}
            >
              <img src="/assets/images/icons/download-icon.svg" alt="" />
              Arker Deck
            </button>
          </a>

          <a
            href="https://playarker.com/assets/docs/ia.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={`${styles["fit-button"]} ${styles["download-button"]}`}
            >
              <img src="/assets/images/icons/download-icon.svg" alt="" />
              IA Integration
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
