import { useLocation, useNavigate } from "react-router-dom";
import styles from "./marketplace-egg-info.module.css";
import { useQuery } from "@apollo/client";
import {
  SaleItemQuery,
  historyQuery,
} from "../../../../services/graphql-queries";
import { useEffect, useState } from "react";
import { getPetsAddress } from "../../../../services/pets";
import { useAccount, useBlockNumber, useChainId, useSwitchChain } from "wagmi";
import { useMaxExpireDate } from "../../../../hooks/marketHooks/useMaxExpireDate";
import { useBuy } from "../../../../hooks/marketHooks/useBuy";
import { useSell } from "../../../../hooks/marketHooks/useSell";
import { useApproveForAll } from "../../../../hooks/eggsHooks/useApproveForAll";
import { useHatch } from "../../../../hooks/eggsHooks/useHatch";
import { useAllowance } from "../../../../hooks/tokenHooks/useAllowance";
import { useApprove } from "../../../../hooks/tokenHooks/useApprove";
import toast, { Toaster } from "react-hot-toast";
import eggsABI from "../../../../assets/abis/breeder.json";
import marketABI from "../../../../assets/abis/marketplace.json";
import {
  MAX_INT,
  getArkerTokenAddress,
  getChainId,
  getMarketAddress,
} from "../../../../services/utils";
import { erc20Abi, parseEther } from "viem";
import moment from "moment";
import { getEggsAddress } from "../../../../services/eggs";
import { MarketplaceRelatedItems } from "../../marketplace-related-items/marketplace-related-items";
import { MarketplaceItemCharacteristics } from "../../marketplace-item-characteristics/marketplace-item-characteristics";
import { getExplorerURL, truncateETHAddress } from "../../../../services/utils";
import { useParseEggData } from "../../../../hooks/eggsHooks/useParseEggData";
import { useApprovedForAll } from "../../../../hooks/eggsHooks/useApprovedForAll";
import { ParentsInfo } from "./parents-info/parents-info";
import { publicClient } from "../../../../services/viem-client";
import { useGetSaleIdByToken } from "../../../../hooks/marketHooks/useGetSaleIdByToken";

const PETS_ADDRESS = getPetsAddress();
const EGGS_ADDRESS = getEggsAddress();

export const MarketplaceEggInfo = () => {
  //LOCATION VARIABLES
  const { pathname } = useLocation();
  const pathnameArray = pathname.split("/");
  const inventory = pathnameArray[2] === "inventory" ?? false;
  const saleId = !inventory && pathnameArray[4] ? pathnameArray[4] : 0;
  const id = inventory ? pathnameArray[4] : pathnameArray[3];
  const classType = inventory ? pathnameArray[3] : pathnameArray[2];

  //USESTATE VARIABLES
  const [itemData, setItemData] = useState();
  const [itemHistory, setItemHistory] = useState();
  const [loadingWrite, setLoadingWrite] = useState(false);
  const [expire, setExpire] = useState();
  const [amount, setAmount] = useState();
  /* const [showDescription, setShowDescription] = useState(true);
  const [showStatus, setShowStatus] = useState(true); */
  const [showItemActivity, setShowItemActivity] = useState(true);

  //GRAPHQL QUERY
  const { saleItemQueryLoading } = useQuery(SaleItemQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: { saleId: parseInt(saleId) },
    onCompleted(queryData) {
      setItemData(queryData.saleEntities[0]);
    },
    onError(error) {
      console.log(error);
    },
    skip: inventory,
  });

  const { itemHistoryQueryLoading } = useQuery(historyQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: {
      tokenAddress: EGGS_ADDRESS,
      tokenId: parseInt(saleId),
    },
    onCompleted(queryData) {
      setItemHistory(queryData.saleEntities);
    },
    onError(error) {
      console.log(error);
    },
  });

  //WAGMI HOOKS
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();
  const { address } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const navigate = useNavigate();

  //MARKET HOOKS
  const { maxExpireDate, maxExpireDateLoading } = useMaxExpireDate();
  const { buyItemWrite, buyItemError, isBuyItemError } = useBuy();
  const { sellItemWrite, sellItemError, isSellItemError } = useSell();
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    id,
    getEggsAddress()
  );

  //EGGS HOOKS
  const { isApprovedForAll, refetchApprovedForAll } = useApprovedForAll();
  const { approveForAllWrite, approveForAllError, isApproveForAllError } =
    useApproveForAll();
  const { hatchWrite, hatchError, isHatchError } = useHatch();
  const { eggData, eggDataLoading } = useParseEggData(id, tokenSaleId);

  //TOKEN HOOKS
  const { allowance, refetchAllowance } = useAllowance(getMarketAddress());
  const { approveWrite, approveError, isApproveError } = useApprove();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    refetchAllowance();
    refetchApprovedForAll();
  }, [blockNumber, refetchAllowance, refetchApprovedForAll]);

  useEffect(() => {
    if (isSellItemError) {
      toast.error(sellItemError.shortMessage);
    }

    if (isApproveForAllError) {
      toast.error(approveForAllError.shortMessage);
    }
    if (isApproveError) {
      toast.error(approveError.shortMessage);
    }
    if (isBuyItemError) {
      toast.error(buyItemError.shortMessage);
    }
    if (isHatchError) {
      toast.error(hatchError.shortMessage);
    }
  }, [
    sellItemError,
    isSellItemError,
    approveForAllError,
    isApproveForAllError,
    buyItemError,
    isBuyItemError,
    approveError,
    isApproveError,
    hatchError,
    isHatchError,
  ]);

  const handleHatch = async () => {
    setLoadingWrite(true);
    try {
      await hatchWrite({
        abi: eggsABI,
        address: EGGS_ADDRESS,
        functionName: "hatch",
        args: [id],
        chainId: getChainId(),
      });
      toast.success("Egg hatched Successfully!");

      const logs = publicClient.getLogs({
        address: getPetsAddress(),
      });

      console.log(logs);
      //navigate("/inventory/fheral/pets");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleBuyItem = async () => {
    setLoadingWrite(true);

    const date = parseInt(new Date().getTime() / 1000 + "", undefined) + 86400;

    try {
      await buyItemWrite({
        abi: marketABI,
        address: getMarketAddress(),
        functionName: "buy",
        args: [saleId, date],
        chainId: getChainId(),
      });

      toast.success("Egg buyed Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleApproveArker = async () => {
    setLoadingWrite(true);
    try {
      await approveWrite({
        abi: erc20Abi,
        address: getArkerTokenAddress(),
        functionName: "approve",
        args: [getMarketAddress(), MAX_INT],
        chainId: getChainId(),
      });
      toast.success("Approve ARKER Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleApproveForAll = async () => {
    setLoadingWrite(true);
    try {
      await approveForAllWrite({
        abi: eggsABI,
        address: EGGS_ADDRESS,
        functionName: "setApprovalForAll",
        args: [getMarketAddress(), true],
        chainId: getChainId(),
      });
      toast.success("Approve for all eggs Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleSellItem = async () => {
    setLoadingWrite(true);

    const startTime = moment();
    const endTime = moment(expire);

    const secondsDiff = endTime.diff(startTime, "seconds");

    if (secondsDiff <= 0) {
      toast.error("The expire date needs to be in the future");
      setLoadingWrite(false);
      return;
    }

    if (secondsDiff > maxExpireDate) {
      toast.error(
        `You can't set a date longer than ${Math.floor(
          parseInt(maxExpireDate) / (3600 * 24)
        )} days`
      );
      setLoadingWrite(false);
      return;
    }

    if (!amount) {
      toast.error("Set an amount");
      setLoadingWrite(false);
      return;
    }

    try {
      await sellItemWrite({
        abi: marketABI,
        address: getMarketAddress(),
        functionName: "sell",
        args: [
          classType === "egg" ? EGGS_ADDRESS : PETS_ADDRESS,
          parseInt(id),
          parseFloat(parseEther(amount ?? "0")),
          secondsDiff,
        ],
        chainId: getChainId(),
      });
      setLoadingWrite(false);
      toast.success("Sell Order Placed Successfully!");
      setAmount(0);
      setExpire();
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  if (
    saleItemQueryLoading ||
    maxExpireDateLoading ||
    itemHistoryQueryLoading ||
    eggDataLoading ||
    saleIdLoading
  ) {
    return <h1 className="text-center my-5 text-white">Loading…</h1>;
  }

  return (
    <>
      <Toaster />
      <div className={`${styles["marketplace-egg-info-container"]}`}>
        <div className={styles.breadcrumbs}>
          <span onClick={() => navigate(-1)}>
            {!inventory ? "Market" : "Inventory"} &gt; {classType} &gt;{" "}
            {eggData.type} &gt; {eggData.name}
          </span>
        </div>

        <div className={styles.top}>
          <div className={`${styles["image-container"]}`}>
            {inventory && eggData.onSale && !eggData.isBanned && (
              <img
                className={styles["for-sale"]}
                src={`/assets/images/marketplace/icons/forsale.png`}
                alt=""
              />
            )}
            <div className={`${styles["egg-image-container"]}`}>
              <img
                className={`${styles["egg-image"]}`}
                src={`/assets/images/marketplace/eggs/${eggData.type}.png`}
                alt=""
              />
            </div>
          </div>

          <div className={styles["egg-info"]}>
            <h1>{eggData.name}</h1>
            <MarketplaceItemCharacteristics
              classType={"egg"}
              type={eggData.type}
            />
            {!inventory && (
              <span className={styles.available}>
                Available until{" "}
                {moment.unix(itemData?.expire).format("MMMM DD, YYYY")}
              </span>
            )}
            {!inventory && (
              <div className={styles["current-price"]}>
                <span>Current price</span>
                <div className={styles.price}>
                  <img src="/assets/images/icons/arker-icon.svg" alt="" />
                  <span>{itemData?.amount} ARKER</span>
                </div>
              </div>
            )}

            <div className={styles["buttons-container"]}>
              {!inventory && !saleItemQueryLoading && (
                <>
                  {!address ? (
                    <button
                      className={`${styles["connect-wallet-button"]} ${styles["disabled"]} button-green`}
                    >
                      Connect wallet first
                    </button>
                  ) : (
                    <>
                      {chainId !== getChainId() ? (
                        <button
                          className={`${styles["buy-button"]} button-green`}
                          onClick={() =>
                            switchChain({
                              chainId: getChainId(),
                            })
                          }
                        >
                          {loadingWrite ? "Loading…" : "Change network"}
                        </button>
                      ) : (
                        <>
                          {allowance < parseEther(itemData?.amount ?? "0") ? (
                            <button
                              className={`${styles["buy-button"]} button-green`}
                              onClick={handleApproveArker}
                            >
                              {loadingWrite ? "Loading…" : "Approve ARKER"}
                            </button>
                          ) : (
                            <>
                              {itemData?.wallet.toLowerCase() ===
                              address?.toLowerCase() ? (
                                <button
                                  className={`${styles["buy-button"]} ${styles["disabled"]} button-green`}
                                >
                                  You're the owner
                                </button>
                              ) : (
                                <button
                                  className={`${styles["buy-button"]} button-green`}
                                  onClick={handleBuyItem}
                                >
                                  {loadingWrite ? "Loading…" : "Buy Now"}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {inventory && (
                <div className={styles["inventory-buttons-container"]}>
                  {!address ? (
                    <button
                      className={`${styles["connect-wallet-button"]} ${styles["disabled"]} button-green`}
                    >
                      Connect wallet first
                    </button>
                  ) : (
                    <>
                      {chainId !== getChainId() ? (
                        <button
                          className={`${styles["buy-button"]} button-green`}
                          onClick={() =>
                            switchChain({
                              chainId: getChainId(),
                            })
                          }
                        >
                          {loadingWrite ? "Loading…" : "Change network"}
                        </button>
                      ) : (
                        <>
                          {isApprovedForAll ? (
                            <div className={styles["sell-container"]}>
                              <input
                                onChange={(e) => setAmount(e.target.value)}
                                type="text"
                                placeholder="Amount"
                                className={styles["amount-input"]}
                              />
                              <input
                                onChange={(e) => setExpire(e.target.value)}
                                type="text"
                                placeholder="Expire Date"
                                onFocus={(e) =>
                                  (e.target.type = "datetime-local")
                                }
                                onBlur={(e) => (e.target.type = "text")}
                                className="mt-2"
                              />
                              <button
                                className={`${
                                  styles["buy-button"]
                                } button-green ${
                                  !amount || !expire || loadingWrite ? (
                                    styles.disabled
                                  ) : (
                                    <></>
                                  )
                                }`}
                                disabled={!amount || !expire}
                                onClick={handleSellItem}
                              >
                                {loadingWrite ? "Loading…" : "Place sell order"}
                              </button>
                            </div>
                          ) : (
                            <button
                              className={`${styles["buy-button"]} button-green`}
                              onClick={handleApproveForAll}
                            >
                              {loadingWrite ? "Loading…" : "Approve"}
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* HATCH BUTTON */}
                  {eggData.canHatch ? (
                    <button
                      className={`${styles["add-wishlist-button"]} see-detail-button`}
                      onClick={handleHatch}
                    >
                      hatch!
                    </button>
                  ) : loadingWrite ? (
                    <button
                      className={`${styles["add-wishlist-button"]} see-detail-button`}
                    >
                      Loading…
                    </button>
                  ) : (
                    <button
                      className={`${styles["add-wishlist-button"]} see-detail-button`}
                    >
                      {eggData.hoursToHatch}
                    </button>
                  )}
                </div>
              )}

              {!inventory &&
                itemData?.wallet.toLowerCase() !== address?.toLowerCase() && (
                  <button
                    className={`${styles["add-wishlist-button"]} see-detail-button`}
                  >
                    Add to wishlist
                  </button>
                )}
            </div>
            <p className={styles.owned}>
              Owned by{" "}
              <a
                href={`${getExplorerURL()}/address/${itemData?.wallet}`}
                target="_blank"
                rel="noreferrer"
              >
                {!inventory
                  ? truncateETHAddress(itemData?.wallet) ?? "unkown"
                  : "You"}
              </a>
            </p>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles["egg-description-container"]}>
            {/* <div
              className={`${styles["egg-description"]}`}
            >
              <div
                className={styles["description-header"]}
                onClick={() => setShowDescription(!showDescription)}
              >
                <span>egg description</span>
                <img
                  className={`${showDescription && styles["rotate"]}`}
                  src="/assets/images/icons/bottom-arrow-icon-green.svg"
                  alt=""
                />
              </div>

              <>
                <div
                  className={`${styles["description-content"]} ${
                    showDescription && styles["description-content-show"]
                  }`}
                >
                  <div className={styles.info}>
                    <div className={styles["info-egg"]}>
                      <span className={styles["info-egg-name"]}>Class:</span>
                      <span className={styles["info-egg-value"]}>
                        {classType}
                      </span>
                    </div>
                    <div className={styles["info-egg"]}>
                      <span className={styles["info-egg-name"]}>type:</span>
                      <span className={styles["info-egg-value"]}>
                        {eggData.type}
                      </span>
                    </div>
                    <div className={styles["info-egg"]}>
                      <span className={styles["info-egg-name"]}>rarity:</span>
                      <span className={styles["info-egg-value"]}>
                        {eggData.rarity}
                      </span>
                    </div>
                  </div>
                  <div className={styles["description-text"]}>
                    <p>{eggData.description}</p>
                  </div>
                </div>

                <>
                  <div
                    className={styles["description-header"]}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <span>status</span>
                    <img
                      className={`${showStatus && styles["rotate"]}`}
                      src="/assets/images/icons/bottom-arrow-icon-green.svg"
                      alt=""
                    />
                  </div>

                  <div
                    className={`${styles.stats} ${
                      showStatus && styles["stats-show"]
                    }`}
                  >
                    <div className={styles.attack}>
                      <img
                        src="/assets/images/marketplace/stats/attack.svg"
                        alt=""
                      />
                      <span>Attack +{eggData.attack}</span>
                    </div>
                    <div className={styles.life}>
                      <img
                        src="/assets/images/marketplace/stats/life.svg"
                        alt=""
                      />
                      <span>Life +{eggData.life}</span>
                    </div>
                    <div className={styles.energy}>
                      <img
                        src="/assets/images/marketplace/stats/energy.svg"
                        alt=""
                      />
                      <span>Energy +{eggData.energy}</span>
                    </div>
                    <div className={styles.agility}>
                      <img
                        src="/assets/images/marketplace/stats/agility.svg"
                        alt=""
                      />
                      <span>Agility +{eggData.agility}</span>
                    </div>
                    <div className={styles.power}>
                      <img
                        src="/assets/images/marketplace/stats/power.svg"
                        alt=""
                      />
                      <span>Power +{eggData.power}</span>
                    </div>
                  </div>
                </>
              </>
            </div> */}

            {classType === "egg" && !itemHistoryQueryLoading && (
              <div className={styles["egg-activity"]}>
                <div
                  className={styles["description-header"]}
                  onClick={() => setShowItemActivity(!showItemActivity)}
                >
                  <span>Item activity</span>
                  <img
                    className={`${showItemActivity && styles["rotate"]}`}
                    src="/assets/images/icons/bottom-arrow-icon-green.svg"
                    alt=""
                  />
                </div>
                <div
                  className={`${styles["egg-activity-content"]} ${
                    showItemActivity && styles["egg-activity-content-show"]
                  }`}
                >
                  {itemHistory?.length === 0 || itemHistory === undefined ? (
                    <div
                      className={`${styles["egg-activity-content"]} ${
                        showItemActivity && styles["egg-activity-content-show"]
                      }`}
                    >
                      <ul>
                        <li>Event</li>
                        <li>Price</li>
                        <li>To(Wallet)</li>
                        <li>Buyed(Date)</li>
                      </ul>

                      <p>This NFT doesn't have any trade</p>
                    </div>
                  ) : (
                    <table className={styles["egg-activity-table"]}>
                      <thead>
                        <tr>
                          <th>Event</th>
                          <th>Price</th>
                          <th>To (Wallet)</th>
                          <th>Buyed(Date)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemHistory?.map((sale, i) => {
                          return (
                            <tr key={`egg-activity-${i}`}>
                              <td>Sale</td>
                              <td>{sale.amount} ARKER</td>
                              <td>{truncateETHAddress(sale.wallet)}</td>
                              <td>
                                {sale.buyed.toString() === "true"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </div>

          <ParentsInfo
            parentsData={{
              fatherData: eggData.fatherData,
              motherData: eggData.motherData,
            }}
          />
        </div>
        {!inventory && <MarketplaceRelatedItems />}
      </div>
    </>
  );
};
